import axios from 'axios';

import { apiAdminUrl, master_key } from '../../config/settings';
import { getMe } from '../actions/auth';
import { unauthorizedError } from './state';

export const listInAppNotifications = () => (dispatch) => {
  return axios
    .get(`${apiAdminUrl}/in-app-notifications`, {
      headers: {
        Authorization: `Bearer ${master_key}`,
      },
    })
    .then((response) => {
      dispatch({ type: 'LIST_IN_APP_NOTIFICATIONS', data: response.data });
    })
    .catch(function (error) {
      console.log('ERROR', error);
      alert(error);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const create = (item) => (dispatch) => {
  return axios
    .post(`${apiAdminUrl}/in-app-notifications/${item._id}`, item, {
      headers: {
        Authorization: 'Bearer ' + master_key,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      alert('Created');
      dispatch(getMe());
    })
    .catch(function (error) {
      alert('Create ERROR');
      console.log(error);
      console.log(error.response.data);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const update = (item) => (dispatch) => {
  return axios
    .put(`${apiAdminUrl}/in-app-notifications/${item._id}`, item, {
      headers: {
        Authorization: 'Bearer ' + master_key,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      alert('Updated');
      dispatch(getMe());
    })
    .catch(function (error) {
      alert('Update ERROR');
      console.log(error);
      console.log(error.response.data);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const duplicate = (id) => (dispatch) => {
  return axios
    .post(`${apiAdminUrl}/in-app-notifications/${id}/duplicate`, undefined, {
      headers: {
        Authorization: `Bearer ${master_key}`,
        'Content-Type': 'application/json',
      },
    })
    .then((response) => {
      alert('Notification duplicated!');
      dispatch(listInAppNotifications());
    })
    .catch(function (error) {
      alert('Update ERROR');
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};

export const remove = (id) => (dispatch) => {
  return axios
    .delete(`${apiAdminUrl}/in-app-notifications/${id}`, {
      headers: {
        Authorization: 'Bearer ' + master_key,
      },
    })
    .then((response) => {
      alert('Deleted');
      dispatch(getMe());
    })
    .catch(function (error) {
      alert('Delete ERROR');
      console.log(error);
      console.log(error.response.data);
      if (error.response) {
        if (error.response.status === 401) {
          dispatch(unauthorizedError());
        }
      }
    });
};
